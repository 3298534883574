import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImg = ({ src, height, width }) => (
  <LazyLoadImage
    src={src}
    height={height}
    width={width}
    effect="blur"
    alt="User's image"
  />
);

export default LazyImg;
